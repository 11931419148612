* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    -webkit-text-size-adjust: 100%;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overscroll-behavior: none;
}

body {
    font-size: var(--text-size-normal);
    font-family: Verdana;
    overscroll-behavior-y: none;
    -webkit-overscroll-behavior-y: none;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    color: var(--text-color);
    background: repeat-x var(--background-color);
    -webkit-touch-callout: none !important;
}

a,
a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
    color: var(--text-color);
}

a {
    -webkit-user-select: none !important;
}

*:not(input):not(textarea) {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}

@media (prefers-reduced-motion: reduce) {
    * {
        animation: none !important;
        transition-duration: 0s !important;
    }
}

img[src=""] {
    content: url("data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==");
}

div {
    -webkit-tap-highlight-color: rgba(0, 208, 255, 0.33);
}

.noscrollbar::-webkit-scrollbar {
    display: none;
    width: 0 !important;
}

.error {
    z-index: 2;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-size: var(--text-size-xxxlarge);
    line-height: 39px;
    text-align: center;
}

.bouncePanel {
    -webkit-overflow-scrolling: touch;
}

.text-small {
    font-size: var(--text-size-small);
}

.greentext {
    color: var(--text-color-matchstatus);
}

.noDataTapText {
    font-size: var(--text-size-large);
    line-height: 20px;
}

.flexHorizontalAlign,
.flexVerticalAlign {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    direction: var(--direction);
}

.flexHorizontalAlign {
    flex-direction: row;
}

.flexVerticalAlign {
    flex-direction: column;
}

.flexAlignStart {
    justify-content: flex-start;
}

.flexAlignEnd {
    justify-content: flex-end;
}

.noDirection {
    direction: unset;
}

.tableStandings {
    width: 100%;
    font-variant: none;
    font-size: var(--text-size-normal);
    text-align: center;
    border-spacing: 1px;
    direction: var(--direction);
}

.tableStandings img {
    width: 36px;
    display: flex;
}

.tableImageSmall img {
    width: 28px;
}

.tableStandings th {
    border-spacing: 0;
    font-size: var(--text-size-large);
    padding: 4px;
    background-color: var(--listHeaderBackground);
    font-variant: all-small-caps;
    font-weight: normal;
}

.tableStandings tr {
    height: 44px;
    cursor: pointer;
}

.standingsLive {
    color: var(--text-color-matchstatus);
}

.tableFooter,
.stickybanner {
    height: 50px;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    font-size: var(--text-size-xsmall);
}

.tableFooter {
    background-color: var(--topbar-background-color);
}

.stickybanner {
    overflow: hidden;
    z-index: 999999;
    background-color: #ffffff00;
}

.button {
    height: 39px;
    padding: 0 10px;
    background-color: #808080;
    color: white;
    border-radius: 4px;
    font-size: var(--text-size-xlarge);
    font-variant: all-small-caps;
    line-height: 33px;
    text-align: center;
}

.button2{
    margin-top:10px;
    margin-bottom:4px;
    min-width:150px;
    height:40px;
    background-color: var(--match-votebutton-background-color);
    border-radius:4px;
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    font-size: var(--text-size-normal);
    align-content: center;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.dropdown{
    margin-top:10px;
    margin-bottom:4px;
    min-width:150px;
    height:40px;
    border-radius:4px;
    font-size: var(--text-size-normal);
    background-color: var(--match-votebutton-background-color);
    padding-left: 8px;
    padding-right: 8px;
}

select {
    font-size: var(--text-size-normal);
    text-align: center;
}

.addFavoriteModalTitle {
    width: 90%;
    font-size: var(--text-size-xxlarge);
    font-weight: bold;
    font-variant: all-small-caps;
    line-height: 23px;
    text-align: center;
    margin-bottom: 10px;
}

.snackbar {
    display: none;
    border-radius: 10px;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 8px 16px;
    position: fixed;
    z-index: 1000000;
    bottom: 30px;
    font-size: 17px;
    left: 50%;
    transform: translateX(-50%);
}

.snackbarshow {
    display: inline;
    animation: toast_fadein 0.5s, toast_fadeout 0.5s 2.5s;
    animation-fill-mode: forwards;
}

.RatingBad {
    color: #d32f2f;
}

.RatingOkaish {
    color: #f57c00;
}

.RatingAvarage {
    color: #acc53f;
}

.RatingGood {
    color: #388e3c;
}

.RatingExcellent {
    color: #aa02a2;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 26px;
    height: 26px;
    border: 0.15em solid #7c7c7c70;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    background-color: #0000002b;
}

input[type="checkbox"]::before {
    content: "";
    width: 26px;
    height: 26px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: var(--tab-indicator-color);
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input[type="checkbox"]:disabled {
    cursor: not-allowed;
}

.pullToRefreshContainer {
    width: 40px;
    height: 40px;
    position: absolute;
    top: -45px;
    left: calc(50% - 20px);
}

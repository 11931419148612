.icon{
    height:28px;
    width:28px;
    -webkit-mask-size: cover;
}

.menu-icon{
    height:26px;
    width:26px;
    margin-inline-start: 30px;
}

.menuitemContainer{
    margin-inline-end: 8px;
    display: flex;
    /*flex-direction: row-reverse;*/
    align-content: flex-start;
    font-size: 8px;
    align-items: flex-start;
}

.icon-back-arrow{
    background-image: var(--icon-back-arrow);
}

.icon-offline{
    -webkit-mask:var(--icon-offline) no-repeat 50% 50%;
    mask:var(--icon-offline) no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    margin-right:8px;
    background-size:contain;
    background-color: #d81212;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    position: absolute;
    top: 11px;
    left: 48px;
}

.icon-favorite-on{
    -webkit-mask:var(--icon-favorite-star) no-repeat 50% 50%;
    mask:var(--icon-favorite-star) no-repeat 50% 50%;
    -webkit-mask-size: cover;
    margin-right:8px;
    mask-size: cover;
    background-size:contain;
    background-color: var(--listItemFavoriteOnColor);
}

.icon-favorite-off{
    -webkit-mask:var(--icon-favorite-star) no-repeat 50% 50%;
    mask:var(--icon-favorite-star) no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    margin-inline-end:8px;
    background-size:contain;
    background-color: var(--listItemFavoriteOffColor);
}

.icon-favorite-off-topbar{
    background-color: var(--topbar-icon-off-color);
}

.icon-add-favorite{
    -webkit-mask: var(--icon-addfavorite);
    mask: var(--icon-addfavorite);
    -webkit-mask-size: cover;
    mask-size: cover;
    margin-right:8px;
    background-size:contain;
    background-color: var(--listItemFavoriteAddIconColor);
}

.icon-bell{
    -webkit-mask:var(--icon-bell);
    mask: var(--icon-bell);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
}

.icon-bell:active{
    background-color:var(--match-bell-active);
}

.icon-bell-active{
    background-color:var(--match-bell-active);
}

.icon-bell-inactive{
    background-color:var(--match-bell-inactive);
}

.icon-bell-error{
    background-color:var(--match-bell-error);
    animation: blinker 0.2s ease-in-out infinite;
}

.icon-bell-disabled{
    background-color:var(--match-bell-disabled);
    -webkit-mask:var(--icon-bell-disabled);
    mask: var(--icon-bell);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
}

.icon-settings{
    -webkit-mask:var(--icon-settings);
    mask:var(--icon-settings);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
}

.icon-listtype{
    -webkit-mask:var(--icon-listtype);
    mask:var(--icon-listtype);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--match-bell-inactive);
}

.icon-search{
    -webkit-mask: var(--icon-search);
    mask: var(--icon-search);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
}

.icon-debug{
    -webkit-mask: var(--icon-debug);
    mask: var(--icon-debug);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
}

.icon-filter-favorites{
    -webkit-mask: var(--icon-filter-favorite);
    mask: var(--icon-filter-favorite);
   -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
}

.icon-share{
    -webkit-mask: var(--icon-share);
    mask: var(--icon-share);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
}

.icon-attention{
    -webkit-mask: var(--icon-attention);
    mask: var(--icon-attention);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
}

.icon-filter-favorites-active{
    -webkit-mask: var(--icon-star);
    mask: var(--icon-star);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--listItemFavoriteOnColor);
}

.icon-back{
    -webkit-mask: var(--icon-back);
    mask: var(--icon-back);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
}

.icon-close{
    -webkit-mask: var(--icon-close);
    mask: var(--icon-close);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
}

.icon-arrow-out{
    -webkit-mask: var(--icon-arrow-out);
    mask:var(--icon-arrow-out);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--match-stats-loss-color);
}

.icon-arrow-right{
    -webkit-mask: var(--icon-arrow-right);
    mask:var(--icon-arrow-out);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--match-lineup-tshirt-color-away);
}

.icon-showmore{
    -webkit-mask: var(--icon-showmore);
    mask: var(--icon-showmore);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
}

.icon-subout-in{
    -webkit-mask: var(--icon-subout-in);
    mask: var(--icon-subout-in);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--match-stats-win-color);
    flex:none;
    transform: rotateY(180deg);
}

.icon-subout-out{
    -webkit-mask: var(--icon-subout-out);
    mask: var(--icon-subout-out);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--match-stats-loss-color);
    flex:none;
}

.icon-darkmode{
    -webkit-mask: var(--icon-dark-mode);
    mask: var(--icon-dark-mode);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-draghandler{
    -webkit-mask: var(--icon-draghandler);
    mask: var(--icon-draghandler);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-delete{
    -webkit-mask: var(--icon-delete);
    mask: var(--icon-delete);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-language{
    -webkit-mask: var(--icon-language);
    mask: var(--icon-language);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-trophy{
    -webkit-mask: var(--icon-throphy);
    mask: var(--icon-throphy);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-shoe{
    -webkit-mask: var(--icon-shoe);
    mask: var(--icon-shoe);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-listorder{
    -webkit-mask: var(--icon-listorder);
    mask: var(--icon-listorder);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-shedule{
    -webkit-mask: var(--icon-shedule);
    mask: var(--icon-shedule);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-team{
    -webkit-mask: var(--icon-team);
    mask: var(--icon-team);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-transfers{
    -webkit-mask: var(--icon-transfers);
    mask: var(--icon-transfers);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-tabhome{
    -webkit-mask: var(--icon-tabhome);
    mask: var(--icon-tabhome);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-login{
    -webkit-mask: var(--icon-login);
    mask: var(--icon-login);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-logout{
    -webkit-mask: var(--icon-logout);
    mask: var(--icon-logout);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
    margin-inline-start:12px;
}

.icon-chat{
    -webkit-mask: var(--icon-chat);
    mask: var(--icon-chat);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-video{
    -webkit-mask: var(--icon-video);
    mask: var(--icon-video);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-lineup{
    -webkit-mask: var(--icon-lineup);
    mask: var(--icon-lineup);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-stats{
    -webkit-mask: var(--icon-stats);
    mask: var(--icon-stats);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--icon-default-color);
    flex:none;
}

.icon-thumbup{
    -webkit-mask: var(--icon-thumbup);
    mask: var(--icon-thumbup);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--match-votebutton-selected-background-color);
    flex:none;
}

.icon-thumbdown{
    -webkit-mask: var(--icon-thumbup);
    mask: var(--icon-thumbup);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--text-color-match-red);
    flex:none;
    transform: scale(1, -1);
}

.icon-thumb-disabled{
    background-color: var(--match-bell-inactive);
    opacity: 0.1;
}

.icon-verify_single{
    -webkit-mask: var(--icon-verify_single);
    mask: var(--icon-verify_single);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--match-bell-inactive);
    flex:none;
}

.icon-verify_both{
    -webkit-mask: var(--icon-verify_both);
    mask: var(--icon-verify_both);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    background-color:var(--match-bell-inactive);
    flex:none;
    background-color:var(--match-lineup-tshirt-color-away);
}

.icon-admin{
    -webkit-mask: var(--icon-admin);
    mask: var(--icon-admin);
    -webkit-mask-size: cover;
    mask-size: cover;
    background-size:contain;
    flex:none;
    background-color:var(--tab-indicator-color);
}








.footballField{

    -webkit-mask: var(--football-field);
    mask:var(--football-field);
    background-size: cover;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: var(--match-field-line-color);
    position: relative;
    z-index: -1;
}

.tshirt-container{
    position:relative;
    z-index:1;
}

.tshirt{
    background-size:contain;
    -webkit-mask: var(--icon-tshirt);
    mask:var(--icon-tshirt);
    background-size: cover;
    -webkit-mask-size: cover;
    line-height: 40px;
    text-align: center;
    color:#464646;
    width: 40px;
}

.tshirt-home{
    background-color: var(--match-lineup-tshirt-color-home);
}

.tshirt-away{
    background-color: var(--match-lineup-tshirt-color-away);
}

.no-internet{
    background: var(--no-internet);
    background-size:contain;
    background-size: contain;
    width: 150px;
    height: 140px;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid;
    border-bottom-color: var(--text-color-matchstatus)
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid;
    border-top-color: var(--text-color-match-red)
}

.arrow-down-choice {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid;
    border-top-color: var(--text-color-primary)
}

/*.arrow-right {*/
/*    width: 0;*/
/*    height: 0;*/
/*    border-top: 60px solid transparent;*/
/*    border-bottom: 60px solid transparent;*/

/*    border-left: 60px solid green;*/
/*}*/

/*.arrow-left {*/
/*    width: 0;*/
/*    height: 0;*/
/*    border-top: 10px solid transparent;*/
/*    border-bottom: 10px solid transparent;*/

/*    border-right:10px solid blue;*/
/*}*/

.ball{
    background-image: url("../images/football2.png");
    background-repeat: no-repeat;
    background-size: 15px 15px;
    text-align: center;
    width: 15px;
    height: 15px;
}

.soccercard{
    -webkit-mask: url("data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- - --%3e %3csvg height='800px' width='800px' version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 283.196 283.196' xml:space='preserve'%3e%3cg%3e%3cpath style='fill:%23010002%3b' d='M240.861%2c283.196H42.335c-6.005%2c0-10.878-4.873-10.878-10.878V10.878C31.457%2c4.873%2c36.33%2c0%2c42.335%2c0 h198.526c6.005%2c0%2c10.878%2c4.873%2c10.878%2c10.878v261.44C251.739%2c278.328%2c246.866%2c283.196%2c240.861%2c283.196z'/%3e%3cpath style='fill:%23010002%3b' d='M215.428%2c233.82H67.763c-5.619%2c0-10.171-4.553-10.171-10.176V46.384 c0-5.619%2c4.552-10.176%2c10.171-10.176h147.665c5.619%2c0%2c10.171%2c4.552%2c10.171%2c10.176v177.259 C225.604%2c229.267%2c221.046%2c233.82%2c215.428%2c233.82z'/%3e%3ccircle style='fill:%23010002%3b' cx='141.598' cy='259.715' r='8.838'/%3e%3ccircle style='fill:%23010002%3b' cx='141.598' cy='18.509' r='2.643'/%3e%3c/g%3e%3c/svg%3e");
    mask: url("data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- - --%3e %3csvg height='800px' width='800px' version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 283.196 283.196' xml:space='preserve'%3e%3cg%3e%3cpath style='fill:%23010002%3b' d='M240.861%2c283.196H42.335c-6.005%2c0-10.878-4.873-10.878-10.878V10.878C31.457%2c4.873%2c36.33%2c0%2c42.335%2c0 h198.526c6.005%2c0%2c10.878%2c4.873%2c10.878%2c10.878v261.44C251.739%2c278.328%2c246.866%2c283.196%2c240.861%2c283.196z'/%3e%3cpath style='fill:%23010002%3b' d='M215.428%2c233.82H67.763c-5.619%2c0-10.171-4.553-10.171-10.176V46.384 c0-5.619%2c4.552-10.176%2c10.171-10.176h147.665c5.619%2c0%2c10.171%2c4.552%2c10.171%2c10.176v177.259 C225.604%2c229.267%2c221.046%2c233.82%2c215.428%2c233.82z'/%3e%3ccircle style='fill:%23010002%3b' cx='141.598' cy='259.715' r='8.838'/%3e%3ccircle style='fill:%23010002%3b' cx='141.598' cy='18.509' r='2.643'/%3e%3c/g%3e%3c/svg%3e");
    background-size:contain;
    background-size: cover;
    -webkit-mask-size: cover;
    line-height: 40px;
    text-align: center;
    background-color: #ff0000;
    width: 20px;
    height: 20px;
}

.soccercard.yellow{
    background-color: #eec91d;
}

.soccercard.black{
    background-color: #000000;
}

.cross{
    -webkit-mask: url("data:image/svg+xml,%3c%3fxml version='1.0' encoding='utf-8'%3f%3e %3c!-- - --%3e %3csvg fill='black' width='800px' height='800px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'%3e%3ctitle%3ehealth%3c/title%3e%3cpath d='M29.125 10.375h-7.5v-7.5c0-1.036-0.839-1.875-1.875-1.875h-7.5c-1.036 0-1.875 0.84-1.875 1.875v7.5h-7.5c-1.036 0-1.875 0.84-1.875 1.875v7.5c0 1.036 0.84 1.875 1.875 1.875h7.5v7.5c0 1.036 0.84 1.875 1.875 1.875h7.5c1.036 0 1.875-0.84 1.875-1.875v-7.5h7.5c1.035 0 1.875-0.839 1.875-1.875v-7.5c0-1.036-0.84-1.875-1.875-1.875z'%3e%3c/path%3e%3c/svg%3e");
    mask: url("data:image/svg+xml,%3c%3fxml version='1.0' encoding='utf-8'%3f%3e %3c!-- - --%3e %3csvg fill='black' width='800px' height='800px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'%3e%3ctitle%3ehealth%3c/title%3e%3cpath d='M29.125 10.375h-7.5v-7.5c0-1.036-0.839-1.875-1.875-1.875h-7.5c-1.036 0-1.875 0.84-1.875 1.875v7.5h-7.5c-1.036 0-1.875 0.84-1.875 1.875v7.5c0 1.036 0.84 1.875 1.875 1.875h7.5v7.5c0 1.036 0.84 1.875 1.875 1.875h7.5c1.036 0 1.875-0.84 1.875-1.875v-7.5h7.5c1.035 0 1.875-0.839 1.875-1.875v-7.5c0-1.036-0.84-1.875-1.875-1.875z'%3e%3c/path%3e%3c/svg%3e");
    background-size:contain;
    background-size: cover;
    -webkit-mask-size: cover;
    line-height: 40px;
    text-align: center;
    background-color: #ff0000;
    width: 20px;
    height: 20px;
}

.captain{
    -webkit-mask: var(--icon-captain);
    mask: var(--icon-captain);
    background-size:contain;
    background-size: cover;
    -webkit-mask-size: cover;
    line-height: 40px;
    text-align: center;
    background-color: #d7f316;
    width: 20px;
    height: 20px;
}



